* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.5vw;
  font-family: 'Libre Baskerville', serif;
}

nav {
  width: 100%;
  display: grid;
  align-content: end;
}

.nav_name {
  color: white;
  font-family: 'Lora', serif;
  font-size: 7vw;
  display: grid;
  justify-items: center;
  align-items: center;
}

.nav_name img {
  width: 35vw;
  height: 15vw;
}

.nav_name_cake {
  color: white;
  font-family: 'Lora', serif;
  font-size: 3vw;
  display: grid;
  justify-items: center;
  padding-bottom: 2vw;
}

li {
  color: black;
  font-size: 1.7vw;
  cursor: pointer;
  padding: 1vw 0;
}

.nav_li {
  color: black;
  font-size: 1.7vw;
  cursor: pointer;
}
.li_activ {
  color: rgb(233, 217, 164);
  cursor: pointer;
  white-space: nowrap;
}

li:hover,
.insta_logo:hover {
  scale: 1.15;
  color: rgb(233, 217, 164);
}

nav ul {
  list-style-type: none;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  padding: 0 4vw;
  background: #B6975A;
}

.searchCake {
  width: 11vw;
  border-radius: 15px;
  border: none;
  padding: 0.2vw 1vw;
  outline: none;
}
.fa-times{
  color: #e6cb97;
}


.insta_icon {
  margin: .5vw 3vw;
}

.insta_logo {
  width: 3vw;
}

.phone {
  justify-items: center;
  margin: 3vw 1vw;
}

.call,
.mail {
  color: black;
  font-size: 1.7vw;
  text-align: center;
}

.mail {
  margin-top: 2vw;
}

.choose_cake_div {
  display: grid;
  justify-items: center;
  align-items: center;
}

.all_cakes,
.contacts {
  width: 44vw;
  height: 4vw;
  border-radius: 55px;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0 2vw;
  margin: 3vw;
  background: #FAF3ED;
  box-shadow: 0px 0px 22px 3px rgba(182, 151, 90, 0.82);
}

.all_cakes p,
.contacts p {
  font-size: 2vw;
  text-align: center;
}
.our_contacts{
  display: grid;
  justify-items: center;
  align-items: center;
}

.albums {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  padding: 1vw 2vw;
  gap: 4vw;
}

.first_foto {
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 1vw;
  padding: 1vw 0;
}

.first_foto p,
.my_design p {
  color: black;
}

.first_foto:hover,
.my_design:hover {
  scale: 1.15;
  cursor: pointer;
}

.first_foto img,
.my_design img {
  width: 20vw;
  height: 20vw;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 7px 10px 12px 4px rgba(182,151,90,.82);

}

.copyright {
  margin-left: 40%;
}

.footer img {
  width: 100%;
}

.about_text_and_foto {
  position: relative;
  margin-top: 1.5vw;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 6vw 0;
}

.about_text_and_foto img {
  border-radius: 7px;
  width: 93vw;
  height: 60vw;
  background-size: cover;
  box-shadow: 11px 13px 9px -1px rgba(182, 151, 90, 0.82);
  margin: 2vw;
}

.about_text_and_foto p {
  color: white;
  position: absolute;
  font-size: 3vw;
  margin: 10vw 15vw;
  line-height: 4.5vw;
  text-shadow: 2px 2px 2px black;
}

.bg {
  background: black;
  position: absolute;
  border-radius: 7px;
  width: 93vw;
  height: 60vw;
  opacity: 0.4;
  margin: 2vw;
}

.map {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 2vw 15vw;
  margin-top: 3vw;
}

.map p {
  font-size: 2vw;
}

.fa-arrow-right {
  color: #B6975A;
}

iframe {
  margin: 1vw auto;
  width: 70vw;
  height: 30vw;
  border-radius: 15px;
}

.one_photo_from_alb {
  padding: 1vw 0;
  display: grid;
  justify-items: center;
  cursor: pointer;
}

.one_photo_from_alb p {
  color: black;
  font-size: 1.3vw;
 
}
.title_price{
   padding: .7vw 0;
   display: grid;
   justify-items: center;
   align-items: center;
}


.one_photo_from_alb img {
  max-width: 20vw;
  max-height: 20vw;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 7px 10px 12px 4px rgba(182,151,90,.82);
}

.photos_in_album {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  padding: 5vw 1vw;
}

.btn-scroll-up {
  position: fixed;
  right: .7vw;
  bottom: 5vw;
  z-index: 1000;
  width: 3vw;
  height: 3vw;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0px 0px 8px 3px rgba(182, 151, 90, 0.82);
  transition: .2s;
  display: grid;
  justify-items: center;
  align-items: center;
}

.fa-arrow-up {
  font-size: 2vw;
  color: #B6975A;
}

.fa-arrow-up:hover {
  color: rgb(233, 217, 164);
}

.one_cake_div_and_mail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  padding: 5vw 0;
}

.one_cake_div {
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 2vw;
  margin-left: 2vw;
}

.one_cake_div img {
  max-width: 50vw;
  max-height: 55vw;
  border-radius: 15px;
  box-shadow: 7px 10px 12px 4px rgba(182,151,90,.82);
}

.mail_form {
  width: 34vw;
  height: 60vw;
  border: 1px solid rgb(233, 217, 164);
  border-radius: 15px;
  box-shadow: 7px 10px 12px 4px rgba(182,151,90,.82);
  display: grid;
  justify-items: center;
  padding: 2vw 2vw;
  margin-top: -3.5vw;
}
.mail_form textarea{
width: 20vw;
}


.mail_form h6 {
  text-align: center;
  padding: 0 5vw;
  font-size: 2vw;
}

.mail_form img {
  width: 14vw;
  height: 17vw;
}

.mail_form input {
  width: 20vw;
  height: 3vw;
  border-radius: 15px;
  padding: 0 1vw;
  font-size: 1.7vw;
}
.mail_form input:focus{
  outline: none;
}

.mail_form button {
  width: 20vw;
  height: 3vw;
  border-radius: 15px;
  padding: 0 1vw;
  background: rgb(233, 217, 164);
  border: none;
}
.mail_form button:hover{
  cursor: pointer;
}
.css-wjh20t-MuiPagination-ul {
  justify-content: center;
  padding: 2vw 0;
}
.css-wjh20t-MuiPagination-ul > li > button {
  font-size: 2vw;
  min-width: 5vw;
  height: 5vw;
}
.css-nhb8h9{
  justify-content: center;
}
.css-lrb33l {
  font-size: 3vw;
}
.css-f4jy6p {
  width: 6vw;
  height: 6vw;
  font-size: 2.5vw;
}

.nosmile {
  width: 5vw;
  margin-top: 2vw;
}
.error_try-later{
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 6vw;
}
.error{
  margin: 10vw 0;
}
.please_wait{
  margin: 10vw 0;
}
.one_cake {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 3vw 0;
}
.one_cake >.one_cake_div>p{
  font-size: 2vw;
}
.p_title_price{
  white-space: nowrap;
}
.sentRequest{
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 10vw 2vw;
}
.sentRequest p{
  margin-top: 3vw;
  font-size: 2.5vw;
}
.sentReqImg{
  width: 10vw;
  height: 10vw;
}

.btn_return{
  width: 25vw;
  height: 3vw;
  border-radius: 20px;
  padding: 0 1vw;
  background: rgb(233, 217, 164);
  border: none;
  margin: 3vw 0;
}
.btn_return:hover{
  cursor: pointer;
}
.my_design{
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 1vw;
  padding: 1vw 0;
}
.add_your_photo{
  width: 100%;
  height: 70vw;
  display: grid;
  align-items: center;
  justify-items: center;
}
.design_form {
  width: 50vw;
  height: 50vw;
  border: 1px solid rgb(233, 217, 164);
  border-radius: 15px;
  box-shadow: 11px 13px 9px -1px rgba(182, 151, 90, 0.82);
  display: grid;
  gap: 1vw;
  justify-items: center;
  padding: 2vw 2vw;
}

.design_form h6 {
  text-align: center;
  padding: 0 5vw;
  font-size: 2vw;
}

.design_form img {
  width: 10vw;
}

.design_form input,
textarea {
  width: 25vw;
  height: 3vw;
  border-radius: 15px;
  padding: 0 1vw;
  font-size: 1.7vw;
  display: grid;
  align-items: center;
}
textarea {
  width: 25vw;
  height: 6vw;
  border-radius: 15px;
  padding: 0 1vw;
  font-size: 1.7vw;
  display: grid;
  align-items: center;
}
.design_form input:focus,
textarea:focus{
  outline: none;
}

.design_form button {
  width: 25vw;
  height: 3vw;
  border-radius: 15px;
  padding: 0 1vw;
  background: rgb(233, 217, 164);
  border: none;
}
.design_form button:hover{
  cursor: pointer;
}
input[type="file"]{
 width: 25vw;
}





@media (max-width:768px) {
 
  .nav_name img {
    width: 53vw;
    height: 22vw;
  }

  nav ul {
    height: 10vw;
    padding: 0 2vw;
    grid-template-columns: 1fr 1.5fr 1fr 2fr 2fr .2fr;
    justify-items: center;
  }
  .nav_li{
    font-size: 2.6vw;
    white-space: nowrap;
  }

  li {
    font-size: 2.6vw;
    padding: 1vw 0;
  }

  .about_text_and_foto p {
    font-size: 3.4vw;
    margin: 10vw 10vw;
  }

  .all_cakes,
  .contacts {
    width: 70vw;
    height: 7vw;
    margin-top: 5vw;
  }

  .all_cakes p, .contacts p {
    font-size: 3.3vw;
    text-align: center;
  }

  .albums {
    grid-template-columns: repeat(3, 1fr);
    gap: 2vw;
  }

  .first_foto img {
    width: 26vw;
    height: 26vw;
  }
  .my_design img{
    width: 26vw;
    height: 26vw;
  }

  .btn-scroll-up {
    width: 5.2vw;
    height: 5.2vw;
    right: 1vw;
    bottom: 20vw;
  }

  .fa-arrow-up {
    font-size: 2.5vw;
  }

  .photos_in_album {
    grid-template-columns: repeat(3, 1fr);
    padding: 3vw 1vw;
  }

  .one_photo_from_alb img {
    max-width: 30vw;
    max-height: 27vw;
  }
  .title_price p{
    font-size: 2.1vw;
  }

  .call, .mail {
    font-size: 3.3vw;
  }

  .map p {
    font-size: 3.3vw;
  }

  .fa-phone {
    font-size: 3.7vw;
  }

  iframe {
    width: 72vw;
    height: 60vw;
  }

  .map,
  .call {
    margin-top: 9vw;
  }
  .css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root {
  font-size: 2vw;
  min-width: 5vw;
  height: 5vw;
}
.css-f4jy6p {
  width: 7vw;
  height: 7vw;
  font-size: 3vw;
}
.design_form input, textarea {
  font-size: 2.8vw;
  width: 35vw;
  height: 5vw;
}
.design_form button{
  width: 35vw;
  height: 5vw;
  font-size: 3vw;
}
.design_form{
  width: 70vw;
  height: 70vw;
}
.add_your_photo{
  height: 90vw;
}
.one_cake_div_and_mail{
  height: 78vw;
}
.mail_form{
  width: 40vw;
  height: 74vw;
}
.mail_form input, 
.mail_form button {
    width: 28vw;
    height: 4vw;
    font-size: 2.5vw;
}
.mail_form textarea {
    width: 28vw;
    height: 8vw;
  
}
.mail_form img {
  width: 16vw;
  height: 18vw;
}
}

@media (max-width:460px){
  nav ul{
    padding: 0 2vw;
  }
  .design_form input, textarea,.mail_form {
    font-size: 2vw;
  }
  .css-f4jy6p{
    min-width: 8vw;
    height: 8vw;
    font-size: 3.6vw;
  }
  .design_form input, textarea {
    font-size: 2.8vw;
    width: 35vw;
    height: 5vw;
  }
  .mail_form textarea{
    width: 29vw;
  }
  .design_form button{
    width: 35vw;
    height: 5vw;
    font-size: 3vw;
  }

}